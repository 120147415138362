@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "MadeofScars";
  src: url("./assets/fonts/MadeofScars-Regular.woff2") format("woff2"),
    url("./assets/fonts/MadeofScars-Regular.woff") format("woff");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "SK Seren";
  src: url("./assets/fonts/SKSeren-Regular.woff2") format("woff2"),
    url("./assets/fonts/SKSeren-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SK Seren";
  src: url("./assets/fonts/SKSeren-Bold.woff2") format("woff2"),
    url("./assets/fonts/SKSeren-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Karlsbader Grotesk";
  src: url("./assets/fonts/KarlsbaderGrotesk-400.woff2") format("woff2"),
    url("./assets/fonts/KarlsbaderGrotesk-400.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Karlsbader Grotesk";
  src: url("./assets/fonts/KarlsbaderGrotesk-600.woff2") format("woff2"),
    url("./assets/fonts/KarlsbaderGrotesk-600.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

* {
  box-sizing: border-box;
  font-family: "MadeofScars", sans-serif;
}

html,
body,
#root {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
}

body {
  background: #977e57;
  min-height: 100vh;
}

.card {
  width: 100%;
  height: 100%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
  //transform: rotateX(-35deg);
}

.face {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.back {
  z-index: -1;
  display: block;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  box-sizing: border-box;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.front,
.back {
  transition: all 0.5s linear;
}

.front {
  z-index: 1;
  box-shadow: 1px 0px 8px #444;
  background-color: "#fff";
}
.back {
  box-shadow: 0 0 0 #444;
}

.flipped,
.hovercard:hover {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg) scale(1.2);
  .front {
    box-shadow: 0 0 0 #444;
  }
  .back {
    box-shadow: 1px 0px 8px #444;
  }
}
